import React from "react";
import CustomerViews from "../components/CustomerViews/CustomerViews";
import Layout from "../components/Layout/Layout";
import Discount from "../components/Discount/Discount";
import HappyFaces from "../components/HappyFaces/HappyFaces";
import HeroSecond from "../components/HeroSecond/HeroSecond";
import Seo from "../components/Seo";
import Data from "../utils/data";

const BuyView = () => {
  const viewPageQuery = Data()



  return (
    <>
      <Seo
        title={viewPageQuery.strapiViewPage.viewPageSeo.title}
        description={viewPageQuery.strapiViewPage.viewPageSeo.description}
        canonical="/buy-instagram-views"
        keywords="buy instaggram views"
      />
      <Layout>
        <HeroSecond
          title={viewPageQuery.strapiViewPage.viewHeroSection.heading}
          description={viewPageQuery.strapiViewPage.viewHeroSection.description}
          image={
            viewPageQuery.strapiViewPage.viewHeroSection.heroImage.localFile
              .childImageSharp.gatsbyImageData
          }
          brand={viewPageQuery.strapiLikePage.brandImages}
        />
        <Discount from="views" />
        <HappyFaces />
        <CustomerViews />
      </Layout>
    </>
  );
};
export default BuyView;